import React, { useEffect } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
// import CardIcon from "components/Card/CardIcon.js";
// import CardBody from "components/Card/CardBody.js";
// import CardFooter from "components/Card/CardFooter.js";
import CardActionArea from "@material-ui/core/CardActionArea";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import rondas from "assets/img/rondas.jpg";
import login from "assets/img/login.jpg";
import vaisala from "assets/img/vaisala.jpg";
import eps from "assets/img/eps.jpeg";
import polos from "assets/img/polos.jpg";
import documents from "assets/img/documents.jpg";
import trabajos from "assets/img/trabajos.jpeg";
import aseo from "assets/img/aseo.jpeg";
import incendio from "assets/img/incendio.jpeg";
import controlAcceso from "assets/img/controlAcceso.jpeg";
import transformadores from "assets/img/transformadores.jpeg";
import styles from "assets/jss/material-dashboard-pro-react/views/dashboardStyle.js";
import jwt_decode from "jwt-decode";
const useStyles = makeStyles(styles);

export default function Dashboard() {
  const initialState = {
    eps: false,
    reportes: false,
    rondas: false,
    polos: false,
    vaisala: false,
    transformadores: false,
    trabajos: false,
  };
  const [user, setUser] = React.useState(initialState);
  const classes = useStyles();

  const fetchData = async () => {
    try {
      let userPre = jwt_decode(localStorage.jwtToken);
      setUser(userPre);
      console.log(userPre);
    } catch (error) {
      console.log(error);
    }
  };

  // CARGA INICIAL
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      {user.empresa === 2 ? null : (
        <GridContainer justify="center">
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Electrincal Panel System"
                  height="160"
                  image={eps}
                  title="Electrincal Panel System"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    EPS
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema de gestión del unifilar eléctrico digital junto a
                    dispositivos de medición de energía.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.eps === false || user.eps === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3000/pages/login-page?token=${localStorage.jwtToken}`;
                      // window.location.href = `https://dms.tools/pages/login-page?token=${localStorage.jwtToken}`;
                      window.location.href = `https://epstool.com/pages/login-page?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a EPS
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Report System Management"
                  height="140"
                  image={login}
                  title="Report System Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Reporte de averias
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema de gestión de tiquetes generados por usuarios para
                    averias y falta de suministros en el edificio.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.reportes === false || user.reportes === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3005/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://reportes.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;

                      // window.location.href = "https://dms.tools/admin/dashboard";
                    }}
                  >
                    Ir a Gestión Tiquetes
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>

          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Technical Rounds"
                  height="160"
                  image={rondas}
                  title="Technical Rounds"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Rondas técnicas
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para la gestión de rondas técnicas en equipos del
                    departamento de facilidades.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.rondas === false || user.rondas === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3010/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://rondas.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Rondas
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        </GridContainer>
      )}

      <GridContainer>
        {user.empresa === 2 ? null : (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={polos}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Facilidades de Polos
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para gestión de facilidades en polos de cuartos
                    limpios.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.polos === false || user.polos === undefined ? (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3015/admin/dashboard`;
                      window.location.href = `https://polos.dms.tools/admin/dashboard`;
                    }}
                  >
                    Ir a Gestión Polos - Visualización
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3015/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://polos.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Polos
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        )}
        {user.empresa === 2 ? null : (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={transformadores}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Transformadores
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para gestión de la informacion de transformadores
                    secos.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.transformadores === false ||
                user.transformadores === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3020/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://transformadores.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Transformadores
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        )}
        {user.empresa === 2 ? null : (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={trabajos}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Trabajos Técnicos
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para gestión de trabajos para el personal técnico.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.trabajos === false || user.trabajos === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3020/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://trabajos.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Trabajos
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        )}
        {user.empresa === 2 ? null : (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={aseo}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Control de Aseo
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para contol de limpieza de servicios sanitarios.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.aseo === false || user.aseo === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3020/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://aseo.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Control de limpieza
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        )}
        {user.empresa === 2 ? null : (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={vaisala}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Vaisala
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para gestión de la informacion de sensores vaisala.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.vaisala === false || user.vaisala === undefined ? (
                  <Button disabled block color="info">
                    No autorizado
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3020/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://vaisala.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Vaisala
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        )}

        {user.empresa === 2 ? (
          <GridItem xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  alt="Management"
                  height="160"
                  image={polos}
                  title="Management"
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="h2">
                    Facilidades de Polos
                  </Typography>
                  <Typography
                    variant="body2"
                    color="textSecondary"
                    component="p"
                  >
                    Sistema para gestión de facilidades en polos de cuartos
                    limpios.
                  </Typography>
                </CardContent>
              </CardActionArea>
              <CardActions>
                {user.polos === false || user.polos === undefined ? (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3015/admin/dashboard`;
                      window.location.href = `https://polos.dms.tools/admin/dashboard`;
                    }}
                  >
                    Ir a Gestión Polos - Visualización
                  </Button>
                ) : (
                  <Button
                    block
                    color="info"
                    onClick={() => {
                      // window.location.href = `http://localhost:3015/admin/dashboard?token=${localStorage.jwtToken}`;
                      window.location.href = `https://bsci.dms.tools/admin/dashboard?token=${localStorage.jwtToken}`;
                    }}
                  >
                    Ir a Gestión Polos
                  </Button>
                )}
              </CardActions>
            </Card>
          </GridItem>
        ) : null}

        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Management"
                height="160"
                image={controlAcceso}
                title="Management"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Control de Acceso
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button block>Próximamente</Button>
            </CardActions>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Management"
                height="160"
                image={incendio}
                title="Management"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Alarmas de incendio
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button block>Próximamente</Button>
            </CardActions>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card>
            <CardActionArea>
              <CardMedia
                component="img"
                alt="Management"
                height="160"
                image={documents}
                title="Management"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="h2">
                  Otras aplicaciones
                </Typography>
              </CardContent>
            </CardActionArea>
            <CardActions>
              <Button block>Próximamente</Button>
            </CardActions>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}
